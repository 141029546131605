import { combineReducers } from 'redux';

import storesReducer from './storesReducer';
import settingsReducer from './settingsReducer';
import depotsReducer from './depotsReducer';
import supportCentersReducer from './supportCentersReducer';
import golStoresReducer from './golStoresReducer';
import chartsReducer from './chartsReducer';
import networkDashboardReducer from './networkDashboardReducer';
import wirelessNetworkDashboardReducer from './wirelessNetworkDashboardReducer';
import standAloneArgosReducer from './standAloneArgosReducer';
import networkDepotReducer from './networkDepotReducer';
import distributionCentresReducer from './distributionCentresReducer';
import regionalFulfilmentCentreReducer from './regionalFulfilmentCentreReducer';
import bankReducer from './bankReducer';
import contactCentreReducer from './contactCentreReducer';

const allReducers = combineReducers({
    storesReducer,
    settingsReducer,
    depotsReducer,
    supportCentersReducer,
    golStoresReducer,
    chartsReducer,
    wirelessNetworkDashboardReducer,
    networkDashboardReducer,
    standAloneArgosReducer,
    networkDepotReducer,
    distributionCentresReducer,
    regionalFulfilmentCentreReducer,
    bankReducer,
    contactCentreReducer
})

const reducer = (state, action) => {
    return allReducers(state, action);
};

export default reducer;