import React from 'react';

import './Footer.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Footer = () => {

  const { lastUpdationTime } = useSelector((state) => state.storesReducer);

  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className='footer'>
      <div className='footer-text copy-right'> © 2024 - Tata Consultancy Services Ltd.</div>
      {
        pathName === '/' && <div className='footer-right'>
           <div className='legend'>
            <span>Online</span>
            <div className='online-legend'></div>
            <span>In-Progress</span>
            <div className='assigned-legend'></div>
            <span>Offline</span>
            <div className='offline-legend'></div>
          </div>
          <div className='footer-text'><span style={{ color: '#d0d0d0' }}>Last updated on : </span>{lastUpdationTime.toLocaleString()}</div>
        </div>
      }

      {
        pathName === '/NetworkDashboard' && <div className='footer-right'>
           <div className='network-legend'>
            <span>Network Up</span>
            <div className='network-online-legend'></div>
            <span>Network Hard Down</span>
            <div className='network-offline-legend'></div>
          </div>
          <div className='footer-text'><span style={{ color: '#d0d0d0' }}>Last updated on : </span>{lastUpdationTime.toLocaleString()}</div>
        </div>
      }

    </div>
  );
}

export default Footer;