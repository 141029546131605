import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "5a795fa2-b154-44b9-bc65-c84919baf9e1",
        authority: "https://login.microsoftonline.com/e11fd634-26b5-47f4-8b8c-908e466e9bdf",
        redirectUri: "https://iwatch.sainsburys.co.uk/", //"https://iwatch.sainsburys.co.uk/",  //"http://localhost:3000/", ,//"https://iwatch.sainsburys.co.uk/", , //"http://localhost:3000/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ["api://5a795fa2-b154-44b9-bc65-c84919baf9e1/AllScope"]
}