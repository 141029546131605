import { SET_IS_LOADING_NETWORK_DASHBOARD, SET_NETWORK_DASHBOARD_OVERVIEW } from "../actions";

const initialState = {
    networkHealthOverview: {
        retail: {
            superMarkets: {
                networkCategory: "Supermarkets",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            convenience: {
                networkCategory: "Convenience",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            argosStandAlone: {
                networkCategory: "StandAloneArgos",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            pfs: {
                networkCategory: "PFS",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            }
        },
        supplyChainAndLogistics: {
            depots: {
                networkCategory: "Depots",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            distributionCentres: {
                networkCategory: "Distribution Centres",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            lfCs: {
                networkCategory: "Local Fulfilment Centres",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            rfCs: {
                networkCategory: "Regional Fulfilment Centres",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            }
        },
        bank: {
            lochSide: {
                networkCategory: "Lochside",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            rosyth: {
                networkCategory: "Rosyth",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            newParkSquare: {
                networkCategory: "New Park Square",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            }
        },
        supportCentre: {
            bank: {
                networkCategory: "Bank",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            ssc: {
                networkCategory: "SSC",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            }
        },
        contactCentres: {
            widnes: {
                networkCategory: "Widnes",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            },
            actonGate: {
                networkCategory: "Acton Gate",
                onlineCount: 0,
                offlineCount: 0,
                offlineSites: [],
                onlineSites: []
            }
        }
    },
    isLoadingNetworkDashboard: false,
}

const networkDashboardReducer = (state = initialState, action) => {
    if (action.type === SET_IS_LOADING_NETWORK_DASHBOARD) {
        return {
            ...state,
            isLoadingNetworkDashboard: action.isLoadingNetworkDashboard
        }
    }

    if (action.type === SET_NETWORK_DASHBOARD_OVERVIEW) {
        return {
            ...state,
            networkHealthOverview: action.networkDashboardOverview,
            isLoadingNetworkDashboard: false
        }
    }

    return state;
}



export default networkDashboardReducer;