import { getAsync } from "./apiBase";
import store from '../store';
import { setDepotControllers, setSupportCenterControllers } from "../actions";

const getMobilityControllerStatus = async () => {
    let mobilityControllers = await getAsync('mobilitymanager/getmobilitycontrollerstatus');
    let depotControllers = mobilityControllers.filter(m => m.strDeviceType === 'Depots');
    let sscControllers = mobilityControllers.filter(m => m.strDeviceType === 'SupportCenters');
    debugger
    let onlineDepotControllers = depotControllers.filter(d => d.isUp);
    let offlineDepotControllers = depotControllers.filter(d => !d.isUp);

    store.dispatch(setDepotControllers({
        onlineCount: onlineDepotControllers.length,
        offlineCount: offlineDepotControllers.length,
        offlineDepotControllers: offlineDepotControllers
    }));

    console.log('OFFLINE DEPOTS', offlineDepotControllers);
    let onlineSSCControllers = sscControllers.filter(d => d.isUp);
    let offlineSSCControllers = sscControllers.filter(d => !d.isUp);
    store.dispatch(setSupportCenterControllers({
        onlineCount: onlineSSCControllers.length,
        offlineCount: offlineSSCControllers.length,
        offlineSSCControllers: offlineSSCControllers
    }));
}

export default { getMobilityControllerStatus };