import { getAsync } from "./apiBase";
import store from '../store';
import { setIsLoadingNetworkDashboard, setNetworkHealthOverview, setNetworkDashboardOverview, setIsLoadingWirelessNetworkDashboard, setWirelessNetworkDashboardOverview } from "../actions";


const getNetworkHealthOverview = async () => {
    store.dispatch(setIsLoadingNetworkDashboard(true));
    let networkHealthOverview = await getAsync('networkhealth/getnetworkhealthoverview');
    store.dispatch(setNetworkDashboardOverview(networkHealthOverview));
}

const getWirelessNetworkHealthOverview = async () => {
    store.dispatch(setIsLoadingWirelessNetworkDashboard(true));
    let wirelessNetworkHealthOverview = await getAsync('wirelessnetwork/getwirelessnetworkstatus');
    store.dispatch(setWirelessNetworkDashboardOverview(wirelessNetworkHealthOverview));
}

export default { getNetworkHealthOverview, getWirelessNetworkHealthOverview };