import { SET_ARUBA__API_AUTHENTICATION, SET_DEVICES_OVERVIEW, SET_IS_SAVING, SET_IS_LOADING_SETTINGS, SET_IS_ADMIN_USER, SET_IS_ADDING_CATEGORY, SET_IS_UPDATING_CATEGORY, SET_IS_DELETING_CATEGORY, SET_IS_SAVING_CATEGORY, SET_CATEGORY_API_RESPONSE, SET_HAS_EXECUTIVE_DASHBOARD_USER } from "../actions";

const initialState = {
    arubaApiAuthentication: null,
    isSaving: false,
    isLoading: false,
    devicesOverview: {
        storesApsTotal: 0,
        golStoresApsTotal: 0,
        depotApsTotal: 0,
        sscApsTotal: 0,
        storesSwitchesTotal: 0,
        golStoresSwicthesTotal: 0,
        depotSwicthesTotal: 0,
        sscSwicthesTotal: 0,
        storesCoreSwicthesTotal: 0,
        golStoresCoreSwicthesTotal: 0,
        depotCoreSwicthesTotal: 0,
        sscCoreSwicthesTotal: 0,
        storesPfsSwicthesTotal: 0,
        golStoresPfsSwicthesTotal: 0,
        depotPfsSwicthesTotal: 0,
        sscPfsSwicthesTotal: 0,
        isAdminUser: false
    },
    isSavingCategory: false,
    isDeletingCategory: false,
    categoryApiResponse: null,
    hasExecutiveDashboardUser: false
}

const settingsReducer = (state = initialState, action) => {
    if (action.type === SET_ARUBA__API_AUTHENTICATION) {
        return {
            ...state,
            arubaApiAuthentication: action.arubaApiAuthentication
        }
    }

    if (action.type === SET_IS_ADMIN_USER) {
        return {
            ...state,
            isAdminUser: action.isAdminUser
        }
    }

    if (action.type === SET_DEVICES_OVERVIEW) {
        return {
            ...state,
            devicesOverview: action.devicesOverview,
            isLoading: false
        }
    }

    if (action.type === SET_IS_SAVING) {
        return {
            ...state,
            isSaving: action.isSaving
        }
    }

    if (action.type === SET_IS_LOADING_SETTINGS) {
        return {
            ...state,
            isLoading: action.isLoading
        }
    }

    if (action.type === SET_IS_SAVING_CATEGORY) {
        return {
            ...state,
            isSavingCategory: action.isSavingCategory
        }
    }

    if (action.type === SET_IS_DELETING_CATEGORY) {
        return {
            ...state,
            isDeletingCategory: action.isDeletingCategory
        }
    }

    if (action.type === SET_CATEGORY_API_RESPONSE) {
        return {
            ...state,
            categoryApiResponse: action.categoryApiResponse
        }
    }

    if (action.type === SET_HAS_EXECUTIVE_DASHBOARD_USER) {
        return {
            ...state,
            hasExecutiveDashboardUser: action.hasExecutiveDashboardUser
        }
    }
    return state;
}

export default settingsReducer;