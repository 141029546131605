import React from 'react';
import Modal from 'react-bootstrap/Modal';

import 'bootstrap/dist/css/bootstrap.min.css';
import './SitesModal.css';


const NetworkUpSitesModal = (props) => {
    const { show, handleClose, onlineSites } = props;
    const onlineCount = onlineSites.length;

    return (
        <Modal show={show} onHide={handleClose} size={onlineCount === 1 ? 'sm' : onlineCount > 10 ? 'lg' : 'md'}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#F06C00', fontSize: 18 }}>Network Up Sites ({onlineCount})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="online-body-content">
                    {onlineSites && onlineSites.map(p => <div className="online-site-item"
                        style={{ width: onlineCount === 1 ? '100%' : onlineCount > 10 ? '48%' : '48%' }}>
                        <div className="online-site-name">{p.siteName}</div>
                        <div className='network-source-label'>Name</div>
                        <div className='network-source-container'>
                            <div className="network-source">{p.source}</div>
                            <div className="network-source-label">Source</div>
                        </div>
                    </div>)}
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default NetworkUpSitesModal;