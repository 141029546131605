import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import 'bootstrap/dist/css/bootstrap.min.css';
import './SitesModal.css';

const NetworkHardDownSitesModal = (props) => {
    const { show, handleClose, offlineSites } = props;
    const offlineCount = offlineSites.length;
    return (
        <Modal show={show} onHide={handleClose} size={offlineCount == 1 ? 'sm' : offlineCount > 10 ? 'lg' : 'md'}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#F06C00', fontSize: 18 }}>Network Hard Down Sites  ({offlineCount})</Modal.Title>
            </Modal.Header>
            <Modal.Body className='network-sites-modal'>
                <div className="online-body-content">
                    {offlineSites && offlineSites.map(p => <div className="offline-site-item"
                        style={{ width: offlineCount == 1 ? '100%' : offlineCount > 10 ? '48%' : '48%' }}>
                        <div className="offline-site-name">{p.siteName}</div>
                        <div className='network-source-label'>Name</div>
                        <div className='network-source-container'>
                            <div className="network-source">{p.source}</div>
                            <div className="network-source-label">Source</div>
                        </div>

                    </div>)}
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default NetworkHardDownSitesModal;