import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { BrowserUtils } from "@azure/msal-browser";
import { useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import settingsApi from '../../api/settingsApi';
import { ReactComponent as Timer } from '../../assets/icons/time_24px.svg';
import { isMobile } from 'react-device-detect';

import './Header.css';
import { Display, PersonCircle, Gear, GraphUpArrow, Diagram3, GearFill, ViewStacked, ViewList, EyeFill, Eye, Eyeglasses, HddNetworkFill, PcDisplayHorizontal, Wifi } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import MenuItem from './MenuItem';

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;

    const { isAdminUser, hasExecutiveDashboardUser } = useSelector((state) => state.settingsReducer);
    const [showAlert, setShowAlert] = useState(false);

    const { accounts, instance } = useMsal();

    useEffect(() => {
        if (accounts[0]) {
            settingsApi.hasAdminUser(accounts[0].username);
            settingsApi.hasExecutiveDashboardUser(accounts[0].username);
        }
    }, []);

    useEffect(() => {
        if (isAdminUser === false && pathName === '/Settings') {
            if (hasExecutiveDashboardUser) {
                navigate('/NetworkDashboard');
            } else {
                navigate('/');
            }
            showWarningAlert();
        }
    }, [isAdminUser]);

    useEffect(() => {
        if (hasExecutiveDashboardUser) {
            navigate('/NetworkDashboard');
        }
    }, [hasExecutiveDashboardUser]);

    const showWarningAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 4000);
    }

    const renderTooltip = (props) => {
        return (
            <Tooltip id="menu-tooltip" {...props}>
                Settings
            </Tooltip>
        );
    }

    const renderMenuItem = (icon, text) => {

    }

    const handleSignOutClick = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        });
    }

    const navigateToHomePage = () => {
        if (hasExecutiveDashboardUser) {
            navigate('/NetworkDashboard');
        } else {
            navigate('/');
        }
    }

    return (
        <div className='header'>
            <div className='header-container'>
                {
                    pathName !== '/' && <div className='main-logo-container' onClick={() => navigateToHomePage()}>
                        <PcDisplayHorizontal style={{ marginRight: 7 }} />
                        <div style={{ color: '#404040', fontWeight: 600 }}>Network i-Watch</div></div>
                }
                {/* <div className={`menu-item ${pathName === '/' ? 'menu-item-selected' : ''}`} onClick={() => navigate('/')}>Dashboard</div> */}
                {/* <div className='menu-item'>Alarms</div>
                    <div className='menu-item'>Inventory Config</div> */}
                {/* {
                        isAdminUser
                            ? <div className={`menu-item ${pathName === '/Settings' ? 'menu-item-selected' : ''}`} onClick={() => navigate('/Settings')}>
                                Settings
                            </div>
                            : <div className={`menu-item menu-item-disabled`} onClick={() => onClickSettings()}>Settings</div>
                    } */}

                {
                    hasExecutiveDashboardUser ?
                        <div className='menu-item-container'>
                            <MenuItem name={'Executive Dashboard'} icon={<ViewList fontSize='18' />} navigateTo={'/NetworkDashboard'} />
                        </div>
                        : <div className='menu-item-container'>

                            <MenuItem name='Monitoring' icon={<Eye fontSize='18' />} navigateTo={'/'} />
                            <MenuItem name='Trends' icon={<GraphUpArrow fontSize='18' />} navigateTo={'/Charts'} />
                            <MenuItem name='Executive Dashboard' icon={<ViewList fontSize='18' />} navigateTo={'/NetworkDashboard'} />
                            <MenuItem name='Wireless Dashboard (beta)' icon={<Wifi fontSize='18' />} navigateTo={'/WirelessNetworkDashboard'} />
                            {/* <MenuItem name='Wan Utilization' icon={<Diagram3 fontSize='18' />} navigateTo={'/WanUtilization'} /> */}
                        </div>
                }



                <div className='settings-panel'>
                    <div className='signed-in-user-name'>
                        <PersonCircle fontSize='20' color='#000' />
                        <div style={{ marginLeft: 5 }}>
                            <Dropdown as={ButtonGroup}>
                                <Button variant="secondary"> {accounts[0] && accounts[0].name}</Button>
                                <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleSignOutClick()}>Sign out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        isAdminUser && <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <div className='settings-icon' onClick={() => navigate('/Settings')}>
                                {pathName === '/Settings'
                                    ? <GearFill fontSize='20' color={'#F06C00'} />
                                    :
                                    <Gear fontSize='20' color={'#000'} />
                                }
                            </div>
                        </OverlayTrigger>
                    }
                </div>
            </div>

            <div style={{ position: 'absolute', left: '40%', top: 20, zIndex: 1 }}>
                {
                    <Alert show={showAlert} variant={'warning'}>
                        You are unable to access this settings page. Permission denied.
                   </Alert>
                }
            </div>
        </div>
    );
}

export default Header;