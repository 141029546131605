import React, { useEffect, useState } from 'react';
import { Bank, Headset } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import networkHealthApi from '../../api/networkHealthApi';
import CartIcon from '../../Components/Icons/CartIcon';
import TruckIcon from '../../Components/Icons/TruckIcon';
import SectionTitleCard from '../../Components/NetworkDashboard/SectionTitleCard';
import SectionItemCard from '../../Components/NetworkDashboard/SectionItemCard';

import './NetworkDashboardPage.css';

import PageLoader from '../../Components/Loaders/PageLoader';
import { networkDataInterval } from '../../utils/constants';

const NetworkDashboardPage = () => {

    const { isLoadingNetworkDashboard, networkHealthOverview } = useSelector((state) => state.networkDashboardReducer);

    useEffect(() => {
        getNetworkDashboardData();
        const refreshDataTimer = setInterval(() => getNetworkDashboardData(), networkDataInterval);

        return () => {
            clearInterval(refreshDataTimer);
        }
    }, []);

    const getNetworkDashboardData = () => {
        networkHealthApi.getNetworkHealthOverview();
    }

    return (
        <div className='parent-container'>
            <div className='network-dashboard-container'>
                <div className="section-container">
                    <SectionTitleCard title='Retail' icon={<CartIcon width={isMobile ? 24 : 37} height={isMobile ? 24 : 37} fill='#fff' />} />
                    <SectionItemCard title="Supermarkets" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.retail.superMarkets.onlineCount}
                        offlineCount={networkHealthOverview.retail.superMarkets.offlineCount}
                        offlineSites={networkHealthOverview.retail.superMarkets.offlineSites}
                        onlineSites={networkHealthOverview.retail.superMarkets.onlineSites} />
                    <SectionItemCard title="Convenience" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.retail.convenience.onlineCount}
                        offlineCount={networkHealthOverview.retail.convenience.offlineCount}
                        offlineSites={networkHealthOverview.retail.convenience.offlineSites}
                        onlineSites={networkHealthOverview.retail.convenience.onlineSites} />
                    <SectionItemCard title="Stand Alone Argos (SaS)" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.retail.argosStandAlone.onlineCount}
                        offlineCount={networkHealthOverview.retail.argosStandAlone.offlineCount}
                        offlineSites={networkHealthOverview.retail.argosStandAlone.offlineSites}
                        onlineSites={networkHealthOverview.retail.argosStandAlone.onlineSites} />
                    <SectionItemCard title="PFS" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.retail.pfs.onlineCount}
                        offlineCount={networkHealthOverview.retail.pfs.offlineCount}
                        offlineSites={networkHealthOverview.retail.pfs.offlineSites}
                        onlineSites={networkHealthOverview.retail.pfs.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title='Supply Chain & Logistics' icon={<TruckIcon width={isMobile ? 24 : 37} height={isMobile ? 24 : 37} fill='#fff' />} />
                    <SectionItemCard title="Depots" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supplyChainAndLogistics.depots.onlineCount}
                        offlineCount={networkHealthOverview.supplyChainAndLogistics.depots.offlineCount}
                        offlineSites={networkHealthOverview.supplyChainAndLogistics.depots.offlineSites}
                        onlineSites={networkHealthOverview.supplyChainAndLogistics.depots.onlineSites} />
                    <SectionItemCard title="Distribution Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supplyChainAndLogistics.distributionCentres.onlineCount}
                        offlineCount={networkHealthOverview.supplyChainAndLogistics.distributionCentres.offlineCount}
                        offlineSites={networkHealthOverview.supplyChainAndLogistics.distributionCentres.offlineSites}
                        onlineSites={networkHealthOverview.supplyChainAndLogistics.distributionCentres.onlineSites} />
                    <SectionItemCard title="Local Fulfilment Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supplyChainAndLogistics.lfCs.onlineCount}
                        offlineCount={networkHealthOverview.supplyChainAndLogistics.lfCs.offlineCount}
                        offlineSites={networkHealthOverview.supplyChainAndLogistics.lfCs.offlineSites}
                        onlineSites={networkHealthOverview.supplyChainAndLogistics.lfCs.onlineSites} />
                    <SectionItemCard title="Regional Fulfilment Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supplyChainAndLogistics.rfCs.onlineCount}
                        offlineCount={networkHealthOverview.supplyChainAndLogistics.rfCs.offlineCount}
                        offlineSites={networkHealthOverview.supplyChainAndLogistics.rfCs.offlineSites}
                        onlineSites={networkHealthOverview.supplyChainAndLogistics.rfCs.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title="SSC & Banks" icon={<Bank fontSize={isMobile ? '18px' : '30px'} color='#f6f6f6' />} />
                    {/* <SectionItemCard title="Lochside" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.bank.lochSide.onlineCount}
                        offlineCount={networkHealthOverview.bank.lochSide.offlineCount}
                        offlineSites={networkHealthOverview.bank.lochSide.offlineSites}
                        onlineSites={networkHealthOverview.bank.lochSide.onlineSites} /> */}
                    {/* <SectionItemCard title="Rosyth" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.bank.rosyth.onlineCount}
                        offlineCount={networkHealthOverview.bank.rosyth.offlineCount}
                        offlineSites={networkHealthOverview.bank.rosyth.offlineSites}
                        onlineSites={networkHealthOverview.bank.rosyth.onlineSites} /> */}
                    {/* <SectionItemCard title="New Park Square" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.bank.newParkSquare.onlineCount}
                        offlineCount={networkHealthOverview.bank.newParkSquare.offlineCount}
                        offlineSites={networkHealthOverview.bank.newParkSquare.offlineSites}
                        onlineSites={networkHealthOverview.bank.newParkSquare.onlineSites} /> */}
                    <SectionItemCard title="SSC" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supportCentre.ssc.onlineCount}
                        offlineCount={networkHealthOverview.supportCentre.ssc.offlineCount}
                        offlineSites={networkHealthOverview.supportCentre.ssc.offlineSites}
                        onlineSites={networkHealthOverview.supportCentre.ssc.onlineSites} />
                    <SectionItemCard title="Banks" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.supportCentre.bank.onlineCount}
                        offlineCount={networkHealthOverview.supportCentre.bank.offlineCount}
                        offlineSites={networkHealthOverview.supportCentre.bank.offlineSites}
                        onlineSites={networkHealthOverview.supportCentre.bank.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title='Contact Centres' icon={<Headset fontSize={isMobile ? '18px' : '30px'} color='#f6f6f6' />} />
                    <SectionItemCard title="Widnes" icon={<Headset fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.contactCentres.widnes.onlineCount}
                        offlineCount={networkHealthOverview.contactCentres.widnes.offlineCount}
                        offlineSites={networkHealthOverview.contactCentres.widnes.offlineSites}
                        onlineSites={networkHealthOverview.contactCentres.widnes.onlineSites} />
                    <SectionItemCard title="Acton Gate" icon={<Headset fontSize={isMobile ? '14px' : '18px'} color='#a1a1a1' />}
                        onlineCount={networkHealthOverview.contactCentres.actonGate.onlineCount}
                        offlineCount={networkHealthOverview.contactCentres.actonGate.offlineCount}
                        offlineSites={networkHealthOverview.contactCentres.actonGate.offlineSites}
                        onlineSites={networkHealthOverview.contactCentres.actonGate.onlineSites} />
                </div>
            </div>

            {
                isLoadingNetworkDashboard && <div className='network-dashboard-loader'>
                    <PageLoader />
                </div>
            }
        </div>
    );
}

export default NetworkDashboardPage;