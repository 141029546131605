import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'bootstrap/dist/css/bootstrap.min.css';
import './DevicesModal.css';
import { getDeviceName, getLocationName } from '../../utils/utilities';
import { DeviceType } from '../../utils/constants';
import storesApi from '../../api/storesApi';
import { Spinner } from 'react-bootstrap';
import { XCircle, CardList } from 'react-bootstrap-icons';
import CopyIcon from '../../assets/icons/copy.svg';

const DevicesModal = (props) => {
    const { show, handleClose, failedDevices, failedDevicesCount, deviceType, locationType } = props;
    const [deviceTotal, setDeviceTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
    }, [copied]);

    return (
        <Modal show={show} onHide={handleClose} size='lg' centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#F06C00' }}>{getDeviceName(deviceType)} - Failed Devices by {getLocationName(locationType)} ({failedDevicesCount})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    deviceType === DeviceType.AccessPoint && <div className='device-modal-content'>
                        <Accordion className='device-accordion' onSelect={async (eventKey) => {
                            if (eventKey && eventKey != 'Sainsbury\'s Default') {
                                setIsLoading(true);
                                const deviceTotal = await storesApi.getDeviceTotalByStore(eventKey, DeviceType.AccessPoint);
                                setDeviceTotal(deviceTotal.total);
                                setIsLoading(false);
                            }
                        }}>
                            {
                                failedDevices.length > 0 && failedDevices.map((group, i) => <Accordion.Item key={'accordion' + i} eventKey={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}>
                                    <CopyToClipboard text={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                        onCopy={() => {
                                            setSelectedIndex(i);
                                            setCopied(true);
                                        }}>
                                        <Accordion.Header>
                                            <div className='device-accordion-header'>
                                                {group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                                {
                                                    (selectedIndex === i && copied) && <span style={{ marginLeft: 12, color: 'gray', fontSize: 14 }}>Copied!</span>
                                                }
                                                {/* ({group.devices.length}) */}
                                            </div>
                                        </Accordion.Header>
                                    </CopyToClipboard>
                                    <Accordion.Body>
                                        <div>
                                            {
                                                isLoading ? <div className='accordion-spinner-container'> <Spinner style={{ color: '#E55000' }} /></div>
                                                    : <div>
                                                        <div className='device-ratio'>
                                                            <div className='failed-device-count'>
                                                                <XCircle className='icon-section' color='lightgray' size='25' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.devices.length} </div>
                                                                    <div className='device-count-label'>Failed Devices</div>
                                                                </div>
                                                            </div>
                                                            <div className='total-device-count'>
                                                                <CardList className='icon-section' color='lightgray' size='30' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.site === 'null' ? group.devices.length : deviceTotal} </div>
                                                                    <div className='device-count-label'>  Total devices </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='device-accordion-body'>
                                                            {
                                                                group.devices.map((d, i) => <div key={i} className={`device-card ${group.devices.length === 1 ? 'device-flex' : 'device-width'}`}>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.name}</b> <div className='device-label'>Name</div> </div>
                                                                        <div> <b>{d.model}</b> <div className='device-label' style={{ textAlign: 'right' }}>Model</div> </div>

                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.downReason || '_'}</b> <div className='device-label'>Down Reason</div> </div>
                                                                        <div> <b>{d.ipAddress || '_'}</b> <div className='device-label' style={{ textAlign: 'right' }}>Ip Address</div> </div>
                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.serial || '_'}</b> <div className='device-label'>Serial Number</div> </div>
                                                                        <div> <b>{d.macAddress || '_'}</b> <div className='device-label' style={{ textAlign: 'right' }}>MAC Address</div> </div>
                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.notes || '_'}</b> <div className='device-label'>Notes</div> </div>

                                                                    </div>
                                                                    <div className='card-row'>
                                                                        <div> <b>{moment(d.lastModifiedDate).format('DD MMM YYYY HH:mm:ss') || 0}</b> <div className='device-label'>Last Up Time</div> </div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)
                            }
                        </Accordion>
                    </div>
                }

                {
                    (deviceType === DeviceType.Switch || deviceType === DeviceType.CoreSwitch || deviceType === DeviceType.PfsSwitch) && <div className='device-modal-content'>
                        <Accordion className='device-accordion' onSelect={async (eventKey) => {
                            if (eventKey && eventKey != 'Sainsbury\'s Default') {
                                setIsLoading(true);
                                const deviceTotal = await storesApi.getDeviceTotalByStore(eventKey, DeviceType.Switch);
                                setDeviceTotal(deviceTotal.total);
                                setIsLoading(false);
                            }
                        }}>
                            {
                                failedDevices.length > 0 && failedDevices.map((group, i) => <Accordion.Item key={'accordion' + i} eventKey={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}>
                                    <CopyToClipboard text={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                        onCopy={() => {
                                            setSelectedIndex(i);
                                            setCopied(true);
                                        }}>
                                        <Accordion.Header>

                                            <div className='device-accordion-header'>
                                                {group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                                {
                                                    (selectedIndex === i && copied) && <span style={{ marginLeft: 12, color: 'gray', fontSize: 14 }}>Copied!</span>
                                                }
                                                {/* ({group.devices.length}) */}
                                            </div>
                                        </Accordion.Header>
                                    </CopyToClipboard>
                                    <Accordion.Body>
                                        <div>
                                            {
                                                isLoading ? <div className='accordion-spinner-container'> <Spinner style={{ color: '#E55000' }} /></div>
                                                    : <div>
                                                        <div className='device-ratio'>
                                                            <div className='failed-device-count'>
                                                                <XCircle className='icon-section' color='lightgray' size='25' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.devices.length} </div>
                                                                    <div className='device-count-label'>Failed Devices</div>
                                                                </div>
                                                            </div>
                                                            <div className='total-device-count'>
                                                                <CardList className='icon-section' color='lightgray' size='30' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.site === 'null' ? group.devices.length : deviceTotal} </div>
                                                                    <div className='device-count-label'>  Total devices </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='device-accordion-body'>
                                                            {
                                                                group.devices.map((d, i) => <div key={i} className={`device-card ${group.devices.length === 1 ? 'device-flex' : 'device-width'}`}>
                                                                    <div className='card-row card-row-space'>
                                                                        <div className='device-value-ellipsis'> <b>{d.name}</b> <div className='device-label'>Name</div> </div>
                                                                        <div className='device-value-ellipsis'> <b>{d.model}</b> <div className='device-label'>Model</div> </div>
                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div className='device-value-ellipsis'> <b>{d.switchType}</b> <div className='device-label'>Switch Type</div> </div>
                                                                        <div> <b>{d.ipAddress || '_'}</b> <div className='device-label'>Ip Address</div> </div>
                                                                    </div>
                                                                    <div className='card-row'>
                                                                        <div className='device-value-ellipsis'> <b>{d.serial || '_'}</b> <div className='device-label'>Serial Number</div> </div>
                                                                        <div> <b>{d.macAddress || '_'}</b> <div className='device-label'>MAC Address</div> </div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)
                            }
                        </Accordion>
                    </div>
                }

                {
                    deviceType === DeviceType.Controllers && <div className='device-modal-content'>
                        <Accordion className='device-accordion' onSelect={async (eventKey) => {
                            // if (eventKey && eventKey != 'Sainsbury\'s Default') {
                            //     setIsLoading(true);
                            //     const deviceTotal = await storesApi.getDeviceTotalByStore(eventKey, DeviceType.AccessPoint);
                            //     setDeviceTotal(deviceTotal.total);
                            //     setIsLoading(false);
                            // }
                        }}>
                            {
                                failedDevices.length > 0 && failedDevices.map((group, i) => <Accordion.Item key={'accordion' + i} eventKey={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}>
                                    <CopyToClipboard text={group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                        onCopy={() => {
                                            setSelectedIndex(i);
                                            setCopied(true);
                                        }}>
                                        <Accordion.Header>
                                            <div className='device-accordion-header'>
                                                {group.site === 'null' ? 'Sainsbury\'s Default' : group.site}
                                                {
                                                    (selectedIndex === i && copied) && <span style={{ marginLeft: 12, color: 'gray', fontSize: 14 }}>Copied!</span>
                                                }
                                                {/* ({group.devices.length}) */}
                                            </div>
                                        </Accordion.Header>
                                    </CopyToClipboard>
                                    <Accordion.Body>
                                        <div>
                                            {
                                                isLoading ? <div className='accordion-spinner-container'> <Spinner style={{ color: '#E55000' }} /></div>
                                                    : <div>
                                                        <div className='device-ratio'>
                                                            <div className='failed-device-count'>
                                                                <XCircle className='icon-section' color='lightgray' size='25' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.devices.length} </div>
                                                                    <div className='device-count-label'>Failed Devices</div>
                                                                </div>
                                                            </div>
                                                            <div className='total-device-count'>
                                                                <CardList className='icon-section' color='lightgray' size='30' />
                                                                <div className='count-section'>
                                                                    <div className='device-count'>{group.site === 'null' ? group.devices.length : deviceTotal} </div>
                                                                    <div className='device-count-label'>  Total devices </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='device-accordion-body'>
                                                            {
                                                                group.devices.map((d, i) => <div key={i} className={`device-card ${group.devices.length === 1 ? 'device-flex' : 'device-width'}`}>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.controllerName}</b> <div className='device-label'>Name</div> </div>
                                                                        <div> <b>{d.model || '_'}</b> <div className='device-label' style={{ textAlign: 'right' }}>Model</div> </div>

                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.downReason || '_'}</b> <div className='device-label'>Down Reason</div> </div>
                                                                        <div> <b>{d.ipAddress || '_'}</b> <div className='device-label' style={{ textAlign: 'right' }}>Ip Address</div> </div>
                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.serial || '_'}</b> <div className='device-label'>Serial Number</div> </div>
                                                                        <div> <b>{d.macAddress || '_'}</b> <div className='device-label' style={{ textAlign: 'right' }}>MAC Address</div> </div>
                                                                    </div>
                                                                    <div className='card-row card-row-space'>
                                                                        <div> <b>{d.notes || '_'}</b> <div className='device-label'>Notes</div> </div>

                                                                    </div>
                                                                    <div className='card-row'>
                                                                        <div> <b>{'_'}</b> <div className='device-label'>Last Up Time</div> </div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)
                            }
                        </Accordion>
                    </div>
                }

            </Modal.Body>
        </Modal >
    );
}

export default DevicesModal;