import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './authentication/authConfig';
import DashboardPage from './Pages/Dashboard/DashboardPage';
import ChartsDashboardPage from './Pages/Dashboard/ChartsDashboardPage';
import SettingsPage from './Pages/Settings/SettingsPage';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

import './App.css';
import WanUtilizationPage from './Pages/Dashboard/WanUtilizationPage';
import { CustomNavigationClient } from './utils/CustomNavigationClient';
import WirelessNetworkDashboardPage from './Pages/WirelessNetworkDashboard/WirelessNetworkDashboardPage';
import NetworkDashboardPage from './Pages/NetworkDashboard/NetworkDashboardPage';

const App = ({ instance }) => {

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <div className='body-container'>
          <Header />
          <div style={{ paddingTop: 40 }}>
            <Routes>
              <Route path={'/'} element={<DashboardPage />} />
              <Route path='/Charts' element={<ChartsDashboardPage />} />
              <Route path='/WanUtilization' element={<WanUtilizationPage />} />
              <Route path='/NetworkDashboard' element={<NetworkDashboardPage />} />
              <Route path='/WirelessNetworkDashboard' element={<WirelessNetworkDashboardPage />} />
              <Route path='/Settings' element={<SettingsPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
