import React, { useEffect, useState } from 'react';
import { Bank, Headset } from 'react-bootstrap-icons';
import { isMobile } from 'react-device-detect';
import './WirelessNetworkDashboardPage.css';
import SectionTitleCard from '../../Components/NetworkDashboard/SectionTitleCard';
import SectionItemCard from '../../Components/NetworkDashboard/SectionItemCard';
import { ReactComponent as Trolley } from '../../assets/icons/trolley_24px.svg';
import { ReactComponent as Delivery } from '../../assets/icons/delivery_24px.svg';
import { useSelector } from 'react-redux';
import networkHealthApi from '../../api/networkHealthApi';
import CartIcon from '../../Components/Icons/CartIcon';
import TruckIcon from '../../Components/Icons/TruckIcon';
import PageLoader from '../../Components/Loaders/PageLoader';
import { networkDataInterval } from '../../utils/constants';

const WirelessNetworkDashboardPage = () => {

    const { isLoadingWirelessNetworkDashboard, wirelessNetworkHealthOverview } = useSelector((state) => state.wirelessNetworkDashboardReducer);

    useEffect(() => {
        getWirelessNetworkDashboardData();
        const refreshDataTimer = setInterval(() => getWirelessNetworkDashboardData(), networkDataInterval);

        return () => {
            clearInterval(refreshDataTimer);
        }
    }, []);

    const getWirelessNetworkDashboardData = () => {
        networkHealthApi.getWirelessNetworkHealthOverview();
    }

    return (
        <div className='parent-container'>
            <div className='network-dashboard-container wireless'>
                <div className="section-container">
                    <SectionTitleCard title='Retail' icon={<CartIcon width={isMobile ? 24 : 37} height={isMobile ? 24 : 37} fill='#fff' />} />
                    <SectionItemCard title="Supermarkets" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.retail.superMarkets.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.retail.superMarkets.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.retail.superMarkets.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.retail.superMarkets.onlineSites} />
                    <SectionItemCard title="Convenience" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.retail.convenience.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.retail.convenience.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.retail.convenience.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.retail.convenience.onlineSites} />
                    <SectionItemCard title="Stand Alone Argos (SaS)" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 18 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.retail.argosStandAlone.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.retail.argosStandAlone.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.retail.argosStandAlone.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.retail.argosStandAlone.onlineSites} />
                    <SectionItemCard title="PFS" icon={<CartIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.retail.pfs.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.retail.pfs.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.retail.pfs.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.retail.pfs.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title='Supply Chain & Logistics' icon={<TruckIcon width={isMobile ? 24 : 37} height={isMobile ? 24 : 37} fill='#fff' />} />
                    <SectionItemCard title="Depots" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.depots.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.depots.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.depots.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.depots.onlineSites} />
                    <SectionItemCard title="Distribution Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.distributionCentres.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.distributionCentres.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.distributionCentres.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.distributionCentres.onlineSites} />
                    <SectionItemCard title="Local Fulfilment Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.lfCs.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.lfCs.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.lfCs.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.lfCs.onlineSites} />
                    <SectionItemCard title="Regional Fulfilment Centres" icon={<TruckIcon width={isMobile ? 16 : null} height={isMobile ? 16 : null} fill='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.rfCs.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.supplyChainAndLogistics.rfCs.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.rfCs.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.supplyChainAndLogistics.rfCs.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title='Bank' icon={<Bank fontSize={isMobile ? '18px' : '35px'} color='#f6f6f6' />} />
                    {/* <SectionItemCard title="Lochside" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.bank.lochSide.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.bank.lochSide.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.bank.lochSide.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.bank.lochSide.onlineSites} /> */}
                    <SectionItemCard title="Rosyth" icon={<Bank fontSize={isMobile ? '14px' : '18px'} color='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.bank.rosyth.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.bank.rosyth.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.bank.rosyth.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.bank.rosyth.onlineSites} />
                </div>
                <div className="section-container">
                    <SectionTitleCard title='Contact Centres' icon={<Headset fontSize={isMobile ? '18px' : '35px'} color='#f6f6f6' />} />
                    <SectionItemCard title="Widnes" icon={<Headset fontSize={isMobile ? '14px' : '18px'} color='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.contactCentres.widnes.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.contactCentres.widnes.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.contactCentres.widnes.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.contactCentres.widnes.onlineSites} />
                    <SectionItemCard title="Acton Gate" icon={<Headset fontSize={isMobile ? '14px' : '18px'} color='#7e7e7e' />}
                        onlineCount={wirelessNetworkHealthOverview.contactCentres.actonGate.onlineCount}
                        offlineCount={wirelessNetworkHealthOverview.contactCentres.actonGate.offlineCount}
                        offlineSites={wirelessNetworkHealthOverview.contactCentres.actonGate.offlineSites}
                        onlineSites={wirelessNetworkHealthOverview.contactCentres.actonGate.onlineSites} />
                </div>
            </div>

            {
                isLoadingWirelessNetworkDashboard && <div className='network-dashboard-loader'>
                    <PageLoader />
                </div>
            }
        </div>
    );
}

export default WirelessNetworkDashboardPage;